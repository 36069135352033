<template>
  <div class="container-fluid bg-light px-0 py-3">
    <main class="container-xl">
        <h1>{{titulo}}</h1>
        <p class="fs-3 mt-4">{{descripcion}}</p>
        <div class="d-flex justify-content-center" v-if="verTienda">
          <div class="row row-cols-1 row-cols-md-2 g-3 w-75 mt-5 mb-lg-5">
            <div class="col">
              <div class="card">
                <i class="bi bi-arrow-repeat fs-1"></i>
                <div class="card-body">
                  <h5 class="card-title">Sincronización de precios</h5>
                  <p class="card-text">Cuando cambias de precios en Sait, se actualizan los precios de la tienda</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <i class="bi bi-card-checklist fs-1"></i>
                <div class="card-body">
                  <h5 class="card-title">Supervisa tus operaciones</h5>
                  <p class="card-text">Elabora la factura electrónica y lleva el control de inventarios y costos</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <i class="bi bi-journal-arrow-down fs-1"></i>
                <div class="card-body">
                  <h5 class="card-title">Procesa pedidos rápidamente</h5>
                  <p class="card-text"> Los pedidos se descargan automáticamente a Sait para ser procesados</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <i class="bi bi-credit-card fs-1"></i>
                <div class="card-body">
                  <h5 class="card-title">Múltiples formas de pago</h5>
                  <p class="card-text">Acepta tarjetas, transferencias, pago en tienda y con opcion de meses sin intereses</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-content-center" >
            <div class="row row-cols-1 row-cols-md-2 g-3 w-75 mt-5 mb-lg-5">
              <div class="col">
                <div class="card">
                  <i class="bi bi-calendar2-check fs-1"></i>
                  <div class="card-body">
                    <h5 class="card-title">Múltiples opciones para tu nómina</h5>
                    <p class="card-text">Calcula tu nómina semanal, quincenal o mensualmente</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <i class="bi bi-lightning fs-1"></i>
                  <div class="card-body">
                    <h5 class="card-title">Configuración rápida</h5>
                    <p class="card-text">Fácil de configurar</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
    </main>
    <section class="bg-azul">
      <div class="container p-5">
          <div class="row justify-content-center">
            <template v-if="verTienda">
            <p class="fs-5 w-75 text-light">Mantén tu tienda abierta 24/7, aumenta la visibilidad de tu negocio y convierte a tus clientes eventuales en frecuentes</p>
            <span>
              <a href="#" @click.prevent="$emit('verFormDescarga',4)" class="btn btn-success fw-bold me-2">Descargar</a>
            </span>
            </template>
            <template v-else>
            <p class="fs-5 w-75 text-light">¿Sigues calculando tu nómina manualmente? Descarga Sait Nómina y automatiza esa operación</p>
            <span>
              <a href="#" @click.prevent="$emit('verFormDescarga',3)" class="btn btn-success fw-bold me-2">Descargar</a>
            </span>
            </template>
          </div>
      </div>
    </section>
    <p class="fs-3 py-5" v-if="verTienda">Si tus clientes están en Internet, ¿Porque no estas Tú?</p>
    
  </div>
</template>

<script>
export default {
    name: 'Producto',
    props: { 
        titulo: {type:String, required:true},
        descripcion: {type:String, required:true},
        verTienda: {type:Boolean}
     }
}
</script>
<style scoped>
.bg-azul{
  background-color: #0a58ca;
}
</style>